import React from "react";
import styled from "styled-components";

import StyledContainerWrapper from "../shared/StyledContainerWrapper";

import { StyledDesc3 } from "../shared/StyledTypography";
import { removePadd, mobileContainerWrapper } from "../shared/CommonStyled";
import { COLORS } from "../../globals/designSystem";
import { mq } from "../../globals/utils";

import githubBrands from "../../images/github-brands-gray.svg";
import twitterBrands from "../../images/twitter-brands-gray.svg";
import discordBrands from "../../images/discord-brands-gray.svg";
import facebookBrands from "../../images/facebook-brands-gray.svg";
import instagramBrands from "../../images/instagram-brands-gray.svg";
import youtubeBrands from "../../images/youtube-brands-gray.svg";
import linkedinBrands from "../../images/linkedin-brands-gray.svg";
import hasuraLogoColor from "../../images/hasura-logo-color.svg";
import hasuraLogoWhite from "../../images/hasura-logo-white.svg";

const StyledCopyWriterWrapper = styled.div`
  padding: 22px 0;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  .flexOne {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .copyWrite {
    font-family: "IBM Plex Mono";
    font-size: 13px;
    text-align: center;
  }
  .cloudStatusWrapper {
    background-color: ${COLORS.neutral_4};
    padding: 12px 16px;
    border-radius: 8px;
    margin-left: 24px;
    .cloudStatus {
      display: flex;
      align-items: center;
      .greenCircle {
        background-color: ${COLORS.green_60};
        border-radius: 50%;
        height: 8px;
        width: 8px;
        margin: 0 4px;
      }
      .greenColor {
        color: ${COLORS.green_60};
      }
    }
  }
  .footerSocialIconsWrapper {
    display: flex;
    align-items: center;
    .socialBrands {
      margin: 0 10px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  ${mq.below.xl} {
    align-items: center;
    padding: 30px 0 90px;
    .cloudStatusWrapper {
      margin: 20px 0;
      margin-left: 24px;
    }
  }
  @media (max-width: 850px) {
    flex-direction: column;
    .brand {
      padding-bottom: 16px;
    }
    .copyWrite {
      padding-bottom: 16px;
    }
  }
  ${mq.below.md} {
    align-items: center;
    flex-direction: column;
    padding: 30px 0 90px;
    .brand {
      padding-bottom: 16px;
    }
    .copyWrite {
      padding-bottom: 16px;
    }
    .flexOne {
      flex-direction: column;
    }
    .cloudStatusWrapper {
      margin-left: 0px;
    }
  }
  ${mq.below.xs} {
    .cloudStatusWrapper {
      display: none;
    }
    .copyWrite {
      padding-bottom: 16px;
    }
  }
`;

const CopyWriterWithSidebar = ({ learnPage, isDarkMode }) => {
  return (
    <StyledContainerWrapper css={learnPage ? removePadd : null}>
      <div css={learnPage ? mobileContainerWrapper : null}>
        <StyledCopyWriterWrapper>
          <div className="brand">
            <a href="https://hasura.io/">
              <img
                src={isDarkMode ? hasuraLogoWhite : hasuraLogoColor}
                alt="Brand logo"
                loading="lazy"
              />
            </a>
          </div>
          <div className="flexOne">
            <StyledDesc3
              variant="neutral_60"
              className="copyWrite"
              fontWeight="font_600"
            >
              © {new Date().getFullYear()} Hasura Inc. All rights reserved
            </StyledDesc3>
          </div>
          <div className="footerSocialIconsWrapper">
            <div className="socialBrands">
              <a
                href="https://github.com/hasura/graphql-engine"
                rel="noopener noreferrer"
                aria-label="Github"
              >
                <img loading="lazy" src={githubBrands} alt="Github" />
              </a>
            </div>
            <div className="socialBrands">
              <a
                href="https://twitter.com/hasurahq"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <img loading="lazy" src={twitterBrands} alt="Titter" />
              </a>
            </div>
            <div className="socialBrands">
              <a
                href="https://discord.com/invite/hasura"
                rel="noopener noreferrer"
                aria-label="Discord"
              >
                <img loading="lazy" src={discordBrands} alt="Discord" />
              </a>
            </div>
            <div className="socialBrands">
              <a
                href="https://www.facebook.com/HasuraHQ"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <img loading="lazy" src={facebookBrands} alt="Facebook" />
              </a>
            </div>
            <div className="socialBrands">
              <a
                href="https://www.instagram.com/hasurahq/?hl=en"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <img loading="lazy" src={instagramBrands} alt="Instagram" />
              </a>
            </div>
            <div className="socialBrands">
              <a
                href="https://www.youtube.com/channel/UCZo1ciR8pZvdD3Wxp9aSNhQ"
                rel="noopener noreferrer"
                aria-label="Youtube"
              >
                <img loading="lazy" src={youtubeBrands} alt="Youtube" />
              </a>
            </div>
            <div className="socialBrands">
              <a
                href="https://www.linkedin.com/company/hasura"
                rel="noopener noreferrer"
                aria-label="Linkedin"
              >
                <img loading="lazy" src={linkedinBrands} alt="Linkedin" />
              </a>
            </div>
          </div>
        </StyledCopyWriterWrapper>
      </div>
    </StyledContainerWrapper>
  );
};

export default CopyWriterWithSidebar;
